/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function n() {
  return [0, 0];
}
function t(n) {
  return [n[0], n[1]];
}
function r(n, t) {
  return [n, t];
}
function e(t, r = n()) {
  const e = Math.min(2, t.length);
  for (let n = 0; n < e; ++n) r[n] = t[n];
  return r;
}
function o(n, t) {
  return new Float64Array(n, t, 2);
}
function u() {
  return n();
}
function c() {
  return r(1, 1);
}
function i() {
  return r(1, 0);
}
function f() {
  return r(0, 1);
}
const a = u(),
  l = c(),
  s = i(),
  _ = f(),
  m = Object.freeze(Object.defineProperty({
    __proto__: null,
    ONES: l,
    UNIT_X: s,
    UNIT_Y: _,
    ZEROS: a,
    clone: t,
    create: n,
    createView: o,
    fromArray: e,
    fromValues: r,
    ones: c,
    unitX: i,
    unitY: f,
    zeros: u
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { l as ONES, s as UNIT_X, _ as UNIT_Y, a as ZEROS, t as clone, n as create, o as createView, e as fromArray, r as fromValues, c as ones, i as unitX, f as unitY, m as v, u as zeros };